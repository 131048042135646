import { IShare, ShareType } from "./IShare";

export interface IGuildSquadTemplate
{
    id: number;
    name: string;
    category: string[];
    description?: string;
    createdBy: IUserDetailsSquad;
    editedBy: IUserDetailsSquad;
    units: IGuildSquadUnits[];
    poolUnits: string[] | undefined,
    combatType: number;
    sortOrder: number;
    hasBeenShared?: boolean;
    shareType?: ShareType;
    sharing?: IShare;
    type: string | undefined;
    version?: number;
    datacronAffixes?: string[];
    datacronRequired?: boolean;
}

export interface IPlayerSquadUnits
{
    id: number;
    characterId: string;
    characterName: string;
    preferredUnit?: string;
    poolUnit?: boolean;
}

export interface IGuildSquadUnits extends IPlayerSquadUnits
{
    requirements: ISquadRequirements;
}

export interface ISquadRequirements
{
    hasZeta: boolean;
    hasOmicron: boolean;
    hasUltimate: boolean;
    filters: IGuildSquadFilter;
    subsPriority: 'order' | 'gear';
    subs?: IGuildSquadSubUnits[];
}

export interface IUserDetailsSquad
{
    discordTag: string;
    allyCode: number;
    playerName: string;
    guildName: string;
    createdUTC: any;
}

export interface IGuildSquadSubUnits
{
    id: number;
    characterId: string;
    characterName: string;
    hasZeta: boolean;
    filters: IGuildSquadFilter;
}

export interface IGuildSquadFilter
{
    minGP: number;
    gear: number;
    relic: number;
    stars: number;
}

export interface IFilterSpecialUnitGuildSquad
{
    characterId: string;
    minStars: number;
    minGear: number;
}

export interface IFilterUnitStatsGuildSquad
{
    characterId: string;
    stats: string;
    statsName: string;
}

export const squadCategories = [
    'Event',
    'Squad Arena',
    'Fleet Arena',
    'Fleets',
    'Raids',
    'Raids - Naboo',
    'Raids - HAAT',
    'Raids - HSith',
    'Raids - CPit',
    'Raids - Krayt',
    'Teams - In progress',
    'GAC',
    'GAC - Defense',
    'GAC - Offense',
    '3v3',
    '3v3 - Defense',
    '3v3 - Offense',
    'Conquest',
    'Conquest Sector 1',
    'Conquest Sector 2',
    'Conquest Sector 3',
    'Conquest Sector 4',
    'Conquest Sector 5',
    'TW',
    'TW - Offense',
    'TW - Defense',
    'TB - Light Side',
    'TB LS - Phase 1',
    'TB LS - Phase 2',
    'TB LS - Phase 3',
    'TB LS - Phase 4',
    'TB LS - Phase 5',
    'TB LS - Phase 6',
    'TB - Dark Side',
    'TB DS - Phase 1',
    'TB DS - Phase 2',
    'TB DS - Phase 3',
    'TB DS - Phase 4',
    'TB DS - Phase 5',
    'TB DS - Phase 6',
    'TB ROTE',
    'TB ROTE - Phase 1',
    'TB ROTE - Phase 2',
    'TB ROTE - Phase 3',
    'TB ROTE - Phase 4',
    'TB ROTE - Phase 5',
    'TB ROTE - Phase 6',
    'Mustafar',
    'Corellia',
    'Coruscant',
    'Geonosis',
    'Bracca',
    'Felucia',
    'Dathomir',
    'Kashyyyk',
    'Tatooine',
    'Zeffo',
    'Haven Medical Station',
    'Lothal',
    'Kessel',
    'Mandalore',
    'Malachor',
    'Vandor',
    'Ring of Kafrene',
    'Scarif',
    'Hoth',
    'Death Star'
];

export const allSquadCategories = [
    'All',
    ...squadCategories
];

